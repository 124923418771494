@import "src/styles/lib";

.Menu {
  z-index: 100;
  position: relative;
  background-color: $primary;
  border-top: 1px solid #fff;

  @include tablet {
    display: none;
  }

  a {
    transition: none !important;
  }

  &-items {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__item {
    color: lighten(#000, 2%);
    font-size: 18px;
    margin: 0 20px;
    text-transform: uppercase;
    padding: 15px 0;

    &:hover {
      opacity: 0.7;
    }

    a {
      color: lighten(#000, 21%);
    }

    a:hover {
      color: lighten(#000, 2%);
    }

    &_inner {
      padding: 15px 0;
    }

    &--active {
      color: $secondary !important;
    }
  }

  &-dropdown {
    min-height: 200px;
    padding: 34px 20px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 57px;
    background-color: #fff;
    box-shadow: 2px 18px 19px 0 rgba(93, 93, 93, 0.26);

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 30px;
    }

    &__list-item {
      display: flex;
      flex-direction: column;
    }

    &__list-item-title {
      font-size: 14px;
    }

    a:hover {
      color: $secondary;
    }
  }
}
