@import "src/styles/lib";

.ProductSingle {
  height: 100%;
  margin-top: 30px;

  &--simple {
    .ProductSingle__content__variations {
      border: none !important;
      padding: 0 !important;
      margin-bottom: 4px;
      .label {
        color: #000000;
        font-weight: bold;
      }
      span.color,
      span.size {
        line-height: 25px;
        border: none !important;
        cursor: initial;
      }
    }
    .ProductSingle__variations_wrapper {
      margin: 10px 0;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;
    }

    .ProductSingleDetails {
      margin-top: 10px;
    }
  }
  &__video {
    iframe {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }

  .WishListButton {
    min-width: 50px;
    width: 100%;
    padding: 0 !important;
    max-width: 50px;
    height: 50px;
    margin: 0;
  }
  .ProductSingle__variation-select {
    min-width: 200px;
    border-radius: 8px;
  }

  & > .container {
    @include mobile {
      padding: 0 !important;
    }
  }

  @include mobile {
    margin-top: 0;
  }

  &__content {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    margin-left: 30px;
    min-height: 500px;
    flex-direction: column;

    @include mobile {
      margin-left: 0;
      min-height: auto;
      padding-top: 15px;
    }

    .top-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    &__header {
      border-bottom: 1px $border-color solid;
    }

    &__brand-name {
      height: 21px;
      padding: 0 5px;
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 19px;
      color: $primary !important;
      //border: 1px darken($gray, 23.8%) solid;
    }

    &__title {
      color: $black;
      display: block;
      font-size: 32px;
      line-height: 1.3em;
      font-weight: bold;

      @include mobile {
        font-size: 22px;
        line-height: 24px;
      }
    }

    .ProductPrices {
      margin-top: 10px;
      margin-bottom: 10px;
      h4 {
        font-size: 26px;
        line-height: 1.5em;
      }
    }

    &__description {
      margin-bottom: 30px;
      border-bottom: 1px solid $gray;

      h4 {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3em;
        color: darken($gray, 20.5%);
      }

      p {
        font-size: 16px !important;
      }
    }

    &__variations {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      &_wrapper {
        margin-top: 1rem;
        border-bottom: 1px $border-color solid;
      }
      span {
        margin-bottom: 10px;
        display: inline-block;
      }

      &-name {
        color: #000000 !important;
      }

      span.label {
        width: 100px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3em;
        color: #000000;
      }

      span.size {
        color: $black;
        padding: 0 5px;
        min-width: 24px;
        height: 24px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.7em;
        text-align: center;
        position: relative;
        border: 1px solid #d8d8d8;
        user-select: none;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 8px;
        &.disabled {
          pointer-events: none;
          background-color: #d8d8d8;
          &:before {
            content: "";
            position: absolute;
            width: calc(100% + 10px);
            height: 1px;
            transform: rotate(-45deg) translate(-17px, -1px);
            background: red;
          }
        }
        &.isActive {
          border-color: black;
        }
      }

      span.color {
        //width: 24px;
        //height: 24px;
        cursor: pointer;
        border: 1px solid #d8d8d8;
        //position: relative;
        border-radius: 8px;
        margin-left: 10px;
        padding: 2px 3px;
        &.isActive {
          border-color: $black;
        }

        //div {
        //  width: 75%;
        //  height: 75%;
        //  margin-right: 0 !important;
        //  position: absolute;
        //  left: 50%;
        //  top: 50%;
        //  transform: translate(-50%, -50%);
        //  border-radius: 100%;
        //}

        &.disabled {
          pointer-events: none;

          div {
            opacity: 0.5;
          }

          &:before {
            content: "";
            position: absolute;
            width: calc(100% + 10px);
            height: 1px;
            transform: rotate(-45deg) translate(-11px, 4px);
            background: red;
            z-index: 3;
          }
        }

        &.isActive {
          border-color: $black;
        }
      }

      span.transport-status {
        color: #53d800;
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
      }
    }

    &__actions {
      display: flex;
      margin-top: auto;
      padding-top: 30px;
      border-top: 1px solid $gray;

      @media (min-width: 768px) and (max-width: 992px) {
        flex-direction: column;

        button.btn--addtocart {
          margin: 20px 10px !important;
        }
      }

      @include mobile {
        display: flex;
        padding-top: 25px;
      }

      button.btn--addtocart {
        text-align: center;
        height: 47px;
        color: $white;
        margin: 0 20px;
        display: block;
        flex: 1 1 auto;
        font-size: 16px;
        padding: 0 30px;
        max-width: 300px;
        font-weight: 500;
        line-height: 40px;
        transition: 300ms ease;
        border-radius: 8px;
        background-color: $green;

        &:hover {
          background-color: darken($green, 4%);
        }
        @include tablet {
          padding: 0;
        }
        @include mobile {
          padding: 0 10px;
          max-width: none;
          margin-right: 15px;
        }

        &:disabled {
          background-color: #d8d8d8;
          border-color: #d9d9d9;
          color: #343434 !important;
        }
      }

      .Quantity {
        margin: auto 0;
        &__input {
          button:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          button:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          input {
            font-weight: 600;
          }
        }
      }

      .WishListButton {
        margin: 0;

        button {
          background-color: $gray_light;
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    @include mobile {
      flex-direction: column;
    }
  }
  .Quantity__select {
    select {
      @include mobile {
        padding: 11px 12px 12px;
      }
    }
  }
}

.ProductSingleLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  @include mobile {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
