@import "./../../styles/_lib.scss";

.ProductReviews {
  margin-top: 90px;
  margin-bottom: 90px;

  @include mobile {
    margin-top: 80px;
  }

  &__title {
    color: $black;
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 20px;

    text-transform: uppercase;
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 2fr);

    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(1, 4fr);
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      @include mobile {
        padding: 10px;
      }
    }
    p {
      text-align: center;
    }
  }
}
