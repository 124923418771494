@import "../../styles/lib";

.BrandsList {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 4fr);
  @include mobile {
    grid-gap: 15px;
    grid-template-columns: repeat(3, 4fr);
  }

  &--empty {
    grid-template-columns: repeat(2, 4fr);
  }
}
