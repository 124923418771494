@import "./src/styles/lib";

.Login {
  display: flex;
  &-moto {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    @include mobile {
      justify-content: center;
      align-items: center;
    }
    img {
      width: 150px;
      margin-bottom: 10px;
    }
    h2 {
      font-family: "Bebas Neue", cursive;
      font-size: 45px;
      color: #2d2d2d;
    }
  }

  &__wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &-text {
      display: inline-block;
      margin: 0;
      border-bottom: 1px solid #000;
    }

    button {
      margin-top: 30px;
    }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
