@import "./../../styles/_lib.scss";

.Catalogue {
  margin-top: 50px;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    grid-gap: 30px;
    @include mobile {
      grid-template-columns: repeat(1, 4fr);
      grid-gap: 15px;
    }
  }
}
