@import "src/styles/lib";

.Input {
  display: block;
  padding: 23px 15px !important;
  border-radius: 8px !important;

  &:focus {
    background: transparent;
    border-color: $secondary !important;
  }
}
