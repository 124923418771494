@import "../../../styles/lib";

.UserNavigation {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  margin-left: 25px;

  @include tablet {
    margin-left: 15px;
  }

  &__item {
    margin-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $secondary !important;

    &:hover {
      color: #000 !important;
    }

    a {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      color: $secondary !important;

      &:hover {
        color: #000000 !important;
        text-decoration: none;
      }
    }

    @include tablet {
      margin-left: 10px;
      &__label {
        display: none;
      }
    }
  }
}
