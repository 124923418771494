@keyframes heartbeat {
  0% {
    transform: scale(0.98);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.98);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(0.98);
  }
}

.animation--heart-beat {
  animation: heartbeat 2s infinite;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.animation--rotation {
  animation: rotation 2s infinite linear;
}
