@import "../../../styles/lib";

.CustomArrow {
  display: flex !important;
}

.HomepageSlide {
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-height: 600px;

  @include tablet {
    min-height: 400px;
    img {
      min-height: 400px;
    }
  }

  @include mobile {
    min-height: 300px;
    img {
      min-height: 300px;
    }
  }

  &__item {
    width: 100%;
    height: 600px;
    position: relative;
    cursor: pointer;

    @include tablet {
      height: 400px;
    }

    @include mobile {
      height: 300px;
    }
  }

  &__content {
    position: absolute;
    max-width: 600px;
    width: 100%;
    z-index: 3;
    top: 50%;
    left: 50%;
    background-color: rgba(238, 238, 238, 0.8);

    transform: translate(-50%, -50%);

    border-radius: 8px;
    padding: 30px 20px;
    text-align: center;

    @include mobile {
      max-width: calc(100% - 120px);
    }

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.35em;
      margin-bottom: 20px;
    }
    button {
      margin: 0 auto;
    }

    @media (max-width: 400px) {
      padding: 15px;
      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include mobile {
      height: 300px;
    }
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }
    outline: none !important;
  }

  .slick-arrow {
    top: 50% !important;
    transform: translateY(-50%) !important ;
    z-index: 1;
    width: 30px;
    height: 25px;
    &:before {
      content: "";
      display: none;
    }
    &:hover {
      fill: $primary;
    }
  }

  .slick-prev {
    left: 50px;
    @include mobile {
      left: 5px;
    }
  }
  .slick-next {
    right: 50px;

    @include mobile {
      right: 5px;
    }
  }

  @include tablet {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    min-height: 322px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  @include mobile {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    min-height: 222px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  &__btn-down {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
