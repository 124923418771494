@import "src/styles/lib";

.ProfileSidebar {
  .HomeTabs__tabs {
    justify-content: center;
  }
  @media (max-width: 500px) {
    .HomeTabs__tabs {
      padding-left: 200px;
    }
  }

  .HomeTabs__tab {
    padding: 0;
    a {
      padding: 10px;
    }
  }
  a {
    transition: none !important;
  }
}
