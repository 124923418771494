@import "./../../styles/_lib.scss";

.Contact {
  margin-top: 50px;

  .form-control,
  .form-control:focus {
    color: $black;
  }

  textarea {
    height: 131px !important;
    resize: none;
  }

  &__btn_submit {
    width: 240px !important;
    max-width: 240px !important;
    @include mobile {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  &__address {
    color: $black;
    font-size: 14px;
    margin-top: 20px;
    line-height: 21px;
    @include mobile {
      margin-top: 30px;
    }

    a {
      &:hover {
        color: $primary;
      }
    }
  }
}
