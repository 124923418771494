@import "src/styles/lib";

.CheckoutConfirmation {
  margin-top: 95px;

  &__head {
    text-align: center;
    h1 {
      font-size: 32px;
      line-height: 35px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 17px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
