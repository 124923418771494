@import "src/styles/lib";

.Button {
  min-width: 150px;
  min-height: 35px;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  padding: 10px 20px !important;

  &--purple {
    background-color: $purple !important;
    border-color: $purple !important;
    &:hover {
      background-color: darken($purple, 10%) !important;
    }
  }

  &--pink {
    background-color: #fff !important;
    border-color: $pink !important;
    &:hover {
      background-color: lighten($pink, 10%) !important;
    }
  }

  &--blue {
    background-color: $blue_light !important;
    border-color: $blue_light !important;
    &:hover {
      background-color: darken($blue_light, 10%) !important;
    }
  }
}
