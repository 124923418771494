@import "./../../../styles/_lib.scss";

.Select {
  position: relative;

  @include desktop {
    max-width: 300px;
  }

  select {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    //background-color: $primary;
    height: 34px;
    color: $black;
    outline: none;
    display: block;
    font-size: 14px;
    padding: 0 5px;
    //appearance: none;
    font-weight: 600;

    text-align: center;
    //border-radius: 20px;
    //background-size: 10px;
    //background-color: $gray;
    //background-repeat: no-repeat;
    //background-position: right 15px center;
    //background-image: url("./../../../assets/icons/chevron-down.svg");

    @include mobile {
      //height: 56px;
      //border-radius: 27px;
      display: inline-block;
      //padding-left: 27px;
      //background-position: right 24px center;
      //font-size: 12px;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 13px;

    .Spinner {
      background-color: $gray;
      padding: 3px;

      @include mobile {
        margin-right: 10px;
        padding: 5px;
      }
    }
    .text-secondary {
      color: $black !important;
    }
  }
}
