@import "src/styles/lib";
.Logo {
  display: flex;
  align-items: center;

  &__svg {
    fill: #e2e1d2;
    width: 150px;
    height: 100px;
    @include mobile {
      width: 100px;
      height: 60px;
      padding-left: 20px;
    }
  }

  &__mobile-menu {
    display: none;
    svg {
      color: $secondary;
    }
    @include tablet {
      display: inline-flex;
    }
  }
}
