@import "lib";

.w-100-mobile {
  @include mobile {
    width: 100%;
  }
}

.p-0-mobile {
  @include mobile {
    padding: 0;
  }
}

.hide-on-mobile {
  @include mobile {
    display: none !important;
  }
}

.hide-on-desktop {
  @include desktop {
    display: none !important;
  }
}

.form-control-custom {
  border-radius: 8px !important;
}
