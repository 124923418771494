@import "src/styles/lib";

.Header {
  position: fixed;
  width: 100%;
  z-index: 10;

  &__wrapper {
    z-index: 5;
    position: relative;
  }

  &-search-mobile {
    position: relative;
    display: none;
    background-color: $primary;
    padding: 0 15px 15px 15px;
    width: 100%;
    z-index: 9;

    @include tablet {
      display: flex !important;
    }

    form {
      width: 100%;
    }

    .Search__results {
      width: calc(100% - 30px) !important;
    }
  }
}
