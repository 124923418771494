@import "./../../styles/_lib.scss";

.CatalogueItem {
  grid-row: span 1;
  position: relative;
  grid-column: span 1;
  overflow: hidden;

  background-color: #f5f4f4;
  box-shadow: 0 0 10px 0 rgba(118, 97, 96, 0.45);
  border-radius: 11px;

  &:hover {
    background-color: $primary;
    .CatalogueItem__title {
      transform: scale(1.1);
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  //&__image {
  //  width: 100%;
  //  height: 100%;
  //  display: block;
  //  position: relative;
  //
  //  &:before {
  //    content: "";
  //    top: 0;
  //    left: 0;
  //    width: 100%;
  //    height: 100%;
  //    position: absolute;
  //    background: rgba($black, 0.3);
  //    transition: 400ms ease-in-out;
  //  }
  //
  //  &:hover {
  //    &:before {
  //      background: rgba($black, 0.5);
  //    }
  //  }
  //
  //  img {
  //    width: 100%;
  //    height: 100%;
  //    display: block;
  //    object-fit: cover;
  //    min-height: 210px;
  //  }
  //}

  &__title {
    bottom: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    color: #111;
    font-size: 18px;
    line-height: 18px;
    padding: 100px 20px;

    text-align: center;
    transition: 0.3s;

    span {
      @include mobile {
        overflow: hidden;
        max-height: 37px;
      }
      @include tablet {
        overflow: hidden;
        max-height: 37px;
      }
    }
  }
}
