@import "src/styles/lib";

.ProductSingleSlider {
  width: 520px;
  overflow: hidden;
  position: relative;
  min-height: 100%;
  max-height: 580px;
  padding: 5px;
  border: 1px solid #eeeeee;

  &--out_of_stock {
    .ProductSingleSlider {
      &__thumbs {
        bottom: 90px;
      }
    }
  }

  &__item {
    &:hover {
      cursor: zoom-in;
    }
    img {
      object-fit: cover;
      max-height: 588px;
      @include mobile {
        height: 480px;
        max-height: 480px;
        object-fit: cover;
      }
    }
  }

  &__no-stock {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: flex-end;
    span {
      display: flex;
      align-items: center;
      width: 100px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 5px;
      border-radius: 8px;
      text-transform: lowercase;
      background-color: $danger;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  @include mobile {
    width: auto;
    max-height: 480px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }

    outline: none !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__thumbs {
    z-index: 3;
    left: 20px;
    width: 160px;
    bottom: 20px;
    position: absolute !important;
    transition: all 0.3s ease-in-out;
    .slick-list {
      height: 47px;
      overflow: hidden;
    }
    .slick-track {
      float: left;
    }
    .slick-slide {
      height: 47px;
    }

    &__item {
      border: 1px $gray solid;
      margin-right: 7px !important;
      width: auto !important;
      width: 47px !important;
      height: 47px !important;
      cursor: pointer;
      display: flex !important;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-current {
      .ProductSingleSlider__thumbs__item {
        border: 1px solid $primary;
      }
    }
  }
}

.ProductSingleSliderLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: Block;
    }
  }
  @include mobile {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}
