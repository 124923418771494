@import "src/styles/lib";

.HomeBrands {
  margin-top: 100px;
  padding: 30px 0;
  //background-color: #eeeeee;

  h2 {
    font-size: 20px;
    font-weight: 800;
    text-align: left;
    margin-bottom: 30px;
  }

  .slick-slider {
    padding: 0 100px;
    @include mobile {
      padding: 0 30px;
    }
  }

  .slick-slide {
    border: 1px solid $gray;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    margin-right: 30px;
    background-color: #fff;
    overflow: hidden;

    &:hover {
      opacity: 0.8;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include mobile {
      margin-right: 20px;
    }
  }

  .slick-arrow {
    //top: 50% !important;
    //transform: translateY(-50%) !important ;
    z-index: 1;
    width: 30px;
    height: 25px;
    background-color: #ffffff;
    svg {
      color: #000000 !important;
    }
    &:before {
      content: "";
      display: none;
    }
    &:hover {
      fill: $secondary;
    }
  }

  .slick-prev {
    @include mobile {
      left: 0;
    }
  }
  .slick-next {
    @include mobile {
      right: 0;
    }
  }

  .slick-arrow {
    .Icon {
      width: 40px;
      height: 40px;
    }
    &:hover {
      svg {
        color: $secondary !important;
      }
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__img-wrapper {
    margin: 35px auto;
    width: 50px;
    height: 50px;
    overflow: hidden;
  }
}
