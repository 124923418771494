@import "./../../styles/_lib.scss";

.ProductsList {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 4fr);

  &--empty {
    grid-template-columns: repeat(1, 4fr) !important;
  }

  @include mobile {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 4fr);
  }
  @include tablet {
    grid-template-columns: repeat(2, 4fr);
  }
}

.ProductItem {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;

  &:first-child {
    &__image {
      &__holder {
        max-height: initial;
      }
    }
  }

  &:hover {
    .ProductItem__image__holder {
      border-color: $primary;
    }
    img {
      transform: scale(1.1);
    }
  }

  &__image {
    width: 100%;
    display: block;
    position: relative;
    border-radius: 8px;

    &__holder {
      border: 1px solid transparent;
      height: 290px;
      width: 100%;
      display: block;
      border-radius: 9px;
      overflow: hidden;
      a {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: 0.5s;
      }

      @include tablet {
        max-height: 171px;
      }

      @include mobile {
        max-height: 114px;
      }
    }
  }

  &__brand-name {
    display: block;
    padding: 0;
    margin-bottom: 7px;
    span {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.3em;
      background: $white;
      color: lighten($black, 65%);
      text-transform: capitalize;
    }
  }

  .WishListButton {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0;
    margin-right: 0;
    padding: 5px 5px !important;
    width: 50px !important;
    min-width: 50px !important;

    @include mobile {
      bottom: 5px;
      right: 5px;
    }

    button {
      background-color: $gray_light;
    }
  }

  &__content {
    display: flex;
    padding: 10px 0 0;
    flex-wrap: wrap;
    text-align: left;

    a {
      width: 100%;
      display: block;
      @include mobile {
        width: 100%;
      }
    }

    &__title {
      color: $black;
      display: block;
      font-size: 15px;
      line-height: 1.3em;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .ProductPrices {
      width: 100%;
    }
  }

  &--big {
    grid-row: span 2;
    grid-column: span 2;

    .ProductItem__image {
      flex: 1 1 auto;
      max-height: 509px;

      &__holder {
        max-height: 509px;
        @include mobile {
          max-height: 280px;
        }
        @include tablet {
          max-height: 380px;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .ProductItem__content {
      a {
        width: 90%;
      }
    }

    @include mobile {
      min-height: 400px;
    }
  }
}

.ProductsLoader {
  width: 100%;
  height: auto;
}
