@import "lib";
@import "bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "animations";
@import "./general";

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 14px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.invalid-feedback {
  color: $danger !important;
}
