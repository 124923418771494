@import "src/styles/lib";
.MenuResponsive {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 49px 3px #08080847;
  transition: 0.2s ease-in-out;
  transform: translateX(-100%);
  width: 300px;
  height: 100%;
  padding: 15px;
  z-index: 100;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &__opened {
    transform: translateX(0);
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    .Icon {
      display: inline-flex;
    }
  }

  &__list {
    height: calc(100% - 40px);
    overflow: auto;

    margin-top: 20px;

    &-item {
      padding: 5px;
      &[data-root="true"] {
        border-bottom: 1px solid #ddd;
      }
    }

    &-item--has-children {
      & > div:not(.MenuResponsive__list-item-toggle) {
        //background: $primary;
        padding-left: 10px;
      }
    }

    &-item-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 5px 0;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;

      a {
        display: block;
        width: 100%;
      }
      a.active {
        color: $brown;
      }

      svg {
        transition: 0.3s;
      }

      &[data-opened="true"] {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-item-submenu {
      padding: 5px;
      li {
        margin-bottom: 5px;
      }
      li a {
        font-size: 16px;
        display: block;
      }
      li a.active {
        text-decoration: underline;
      }
    }
  }
}
