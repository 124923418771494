@import "src/styles/lib";

.ProductItemWatermark {
  &__box {
    top: 11px;
    left: 14px;
    display: flex;
    align-items: center;
    justify-self: center;
    position: absolute;
    font-size: 12px;
    line-height: 18px;
    color: white;
    font-weight: 700;
    padding: 12px;
    background-color: $primary;
    border-radius: 8px;
  }

  &--limited-availability {
    background-color: $primary;
  }

  &--discounted-percentage {
    bottom: 28px;
  }

  &--out-of-stock {
    background-color: #f06232;
    color: #f9ebe6;
  }
}
