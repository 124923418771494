@import "src/styles/_lib.scss";

.ApplyCoupon {
  width: 100%;

  &__form {
    border-radius: 22px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .form-control {
    width: calc(100% - 86px);
    background: transparent;
    border: 1px solid #ddd !important;
  }

  &__input {
    background-color: #fff !important;
    border: transparent !important;
    &:focus {
      background-color: #fff !important;
    }
  }

  &__btn {
    min-width: 68px;
    height: 43px;
    position: absolute;
    right: 0;
    top: 28px;
    border: 0 !important;
    border-radius: 8px !important;
    text-transform: uppercase;

    color: #fff !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    opacity: 1 !important;
    &:focus,
    &:active,
    &:hover {
      box-shadow: none !important;
    }
    &.disabled {
      background-color: #d8d8d8 !important;
      color: #000 !important;
    }
    @include mobile {
      top: 19px;
    }
  }

  &__trigger {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: lighten($black, 30%);

    &:hover {
      color: $black;
    }
  }
}
