@import "styles/lib";

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  &__main_content {
    flex: 1;
    padding-top: 186px;

    @include tablet {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    @include mobile {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    img {
      width: 500px;
      height: 500px;
    }
    .LoadingPlaceholder {
      svg {
        color: $primary;
      }
      p {
        font-size: 28px;
        color: $primary;
      }
    }

    .Logo__mobile-menu {
      display: none !important;
    }
    &--logos {
      display: flex;
      margin-bottom: 20px;
      span {
        font-size: 22px;
        color: $secondary;
        margin: 0 20px;
      }
    }
    &--text {
      p {
        color: $primary;
        margin-bottom: 0;
      }
    }
  }

  .container {
    position: relative;
    max-width: 1170px;
    //padding: 0;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: $black;
    //TODO: this is not good practice
    transition: 400ms ease-in-out;
  }

  ul {
    margin-bottom: 0;
    list-style-type: none;
  }

  button {
    outline: none;
    //border: none;

    &:focus {
      outline: none;
    }
  }

  /* Override Bootstrap */
  .btn.btn-flat {
    height: 47px;
    color: $white;
    display: block;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    min-width: 112px;
    background: $black;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &__center {
      margin: 0 auto;
    }

    @include mobile {
      &.full-mob {
        width: 100%;
        max-width: unset;
      }
    }
  }

  .btn:focus {
    box-shadow: none;
  }

  .mt-heading {
    margin-top: 83px;
    @include tablet {
      margin-top: 30px;
    }

    @include mobile {
      margin-top: 15px;
    }
  }

  .form-group {
    margin-bottom: 30px;

    label {
      display: block;
      font-size: 13px;
      text-align: left;
      font-weight: 700;
      line-height: 18px;
      padding-left: 0;
      margin-bottom: 10px;

      color: darken(#eee, 20%);
      text-transform: capitalize;
      @include mobile {
        margin-bottom: 2px;
      }
    }

    select.form-control {
      display: block;
      height: 48px;
      border-radius: 8px !important;
      padding: 0 15px !important;

      &:focus {
        background: #fff;
        border-color: $black;
      }

      &.white {
        background: $white;
      }
    }

    textarea.form-control {
      display: block;
      height: 245px;
      border-color: 1px solid $gray;
      padding: 20px 30px;
      border-radius: 0;
      &:focus {
        background-color: #fff;
        border-color: $black;
      }
    }

    @include mobile {
      margin-bottom: 15px;
    }

    .invalid-feedback {
      padding-left: 0;
    }
  }

  .form-check {
    margin-bottom: 30px;
    label {
      font-size: 16px;
      line-height: 18px;
      font-weight: normal;
      color: black !important;
    }

    label.form-check-label {
    }

    input.form-check-input {
      border-radius: unset;
      border-color: $gray;
      width: 15px;
      height: 14px;
      margin-right: 10px;
    }
  }

  //.form-control.is-invalid,
  //.was-validated .form-control:invalid {
  //  border-color: $form-feedback-invalid-color !important;
  //}

  //.form-control:focus {
  //  border-color: $gray;
  //  background-color: $gray;
  //  box-shadow: unset !important;
  //}

  //TODO: fix this shit
  .Login,
  .ResetPassword,
  .Register,
  .ForgotPassword {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    //background: rgba(33, 33, 33, 0.52);
    position: fixed;
    z-index: 10;
    display: none;
  }

  &--show-backdrop {
    .App__backdrop {
      display: block;
    }
  }
}
