@import "src/styles/lib";

.HomeTestimonials {
  &-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    box-shadow: 0 0 10px 0 rgba(118, 97, 96, 0.45);
    border-radius: 11px;

    h4 {
      color: $secondary;
      font-weight: 600;
    }

    p {
      color: #6f6f6f;
      font-style: italic;
    }

    img {
      width: 100px;
      height: 100px;
      position: relative;
      top: -30px;

      border: 1px solid $secondary;
      border-radius: 50%;
    }
  }

  .slick-list {
    padding: 60px !important;
  }

  .slick-arrow {
    &:before {
      content: "";
      display: none;
    }
  }

  .slick-slide {
    opacity: 0.2;
    text-align: center;

    padding: 15px;

    @include mobile {
      padding: 5px;
    }
  }
  .slick-center {
    opacity: 1;
    .Image--default {
      opacity: 1 !important;
    }
  }

  .slick-arrow {
    .Icon {
      width: 40px;
      height: 40px;
    }

    &:hover {
      svg {
        color: $secondary;
      }
    }

    svg {
      width: 40px;
      height: 40px;
    }
  }
}
