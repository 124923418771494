@import "src/styles/lib";

.HomeTabs {
  width: 100%;
  margin-top: 100px;

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 30px !important;
    overflow: hidden;
    overflow-x: auto;

    a {
      white-space: nowrap;
    }
  }

  &__tab {
    margin: 0 15px;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;

    a {
      color: #000000 !important;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__tab--active {
    background-color: $secondary;
    color: #fff;
    border-bottom: 2px solid $secondary;
  }
}
