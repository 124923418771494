@import "./../../../styles/_lib.scss";

.ShopCategories {
  margin-bottom: 50px;

  a {
    transition: none !important;
  }

  &__title {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 900;
    line-height: 16px;
    margin-bottom: 10px;
    color: $black;
    border-bottom: 1px solid $secondary;
    padding-bottom: 5px;
  }

  &__category {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: $black;
    display: block;
    padding: 10px 0;
    position: relative;
    cursor: pointer;

    &:hover {
      color: lighten($black, 40%);
    }

    &[data-active="true"] {
      font-weight: 700;
      color: $brown;
    }
  }
}
