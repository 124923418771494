@import "src/styles/lib";

.SearchSidebar {
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  visibility: hidden;
  background: #eee;
  box-shadow: 0 0 49px 3px #08080847;
  transition: 0.2s ease-in-out;
  transform: translateX(100%);
  z-index: 100;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @include mobile {
    width: 100%;
  }
  &--opened {
    visibility: visible;
    transform: translateX(0);
  }

  &--updating {
    background: #ffffff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid $primary;
    p {
      margin: 0;
      font-size: 20px;
    }
  }

  &-body {
    height: calc(100% - 88px);
    overflow: auto;
    padding: 20px;

    @include mobile {
      padding: 10px;
    }
  }

  &-list {
    &-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 35px;

      &-image {
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
        padding: 2px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-info {
        padding-left: 20px;
        h4 {
          font-size: 18px;
        }
      }

      &-remove {
        margin-left: auto;
        background: none;
        border: none;
        outline: none;
      }
    }
  }

  &-footer {
    padding: 20px;

    a {
      color: #fff !important;
      font-size: 20px;
      background: $primary;
      padding: 15px;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: darken($primary, 10%);
      }
    }
  }
}
