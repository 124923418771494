@import "./../../styles/_lib.scss";

.RelatedProducts {
  margin-top: 90px;
  margin-bottom: 90px;

  @include mobile {
    margin-top: 80px;
  }

  &__title {
    color: $black;
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 20px;

    text-transform: uppercase;
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 4fr);

    @include tablet {
      grid-template-columns: repeat(3, 4fr);
    }

    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 4fr);

      .ProductItem {
        &:first-child {
          grid-row: unset;
          grid-column: unset;
        }
      }
    }
  }
}

.RelatedProductsLoader {
  @include tablet {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  @include mobile {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
