@import "./src/styles/lib";

.Footer {
  width: 100%;
  background-color: $blue_light;
  padding: 40px 0 0 0;
  margin-top: 40px;

  &__list {
    &-head {
      text-transform: uppercase;
      font-weight: 900;
      color: #000;
      margin-bottom: 40px;
    }
    li {
      margin-bottom: 20px;
    }
    a:hover {
      color: #000000;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      flex-direction: column;
    }
    &__right {
      width: 65%;
      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    &--text {
      width: 35%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &__copyright {
    color: $gray;
    background-color: darken($blue_light, 5%);
    padding: 10px 0;
    margin-top: 15px;

    span {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 35px;
      color: #000000;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
